import axios from 'axios'

const Request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
})

export default {
  getTrucks() {
    return Request.get('/trucks/index')
  },
  sendMail(data) {
    return Request.post('/root/send-mail', data)
  },
  getContacts() {
    return Request.get('/root/contacts')
  },
  getServicesPage() {
    return Request.get('/page/services-terminal')
  },
  getStoPage() {
    return Request.get('/page/services-workshop')
  },
  getNewsPage() {
    return Request.get('/page/news')
  },
  getNewsForMainPage() {
    return Request.get('/page/news?featured=1')
  },
}
