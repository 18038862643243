import { mailTo } from '@/content/emailsList'

export const headerBlock = {
  image: 'sto/sto-header.png',
  title: 'Быстрое качественное обслуживание вашего авто прямо на терминале',
  description:
    'Если у вас есть вопросы и предложения&nbsp;- заполните форму или свяжитесь с нашим отделом СТО&nbsp;<span>+7&nbsp;988&nbsp;314-79-26</span>',
  button: 'Задать вопрос',
  mailTo: mailTo.sto,
}

export const trademarksBlock = {
  title: 'Оказываем услуги по обслуживанию и ремонту грузовых автомобилей ',
  description:
    'Таких марок как: Volvo Trucks, Renault Trucks, HONGYAN и многих других.',
  trademarks: [
    'sto/Volvo.png',
    'sto/Renault.png',
    'sto/Hongyan.png',
    'sto/Shacman.png',
  ],
}

export const pricesBlock = {
  serviceTitle: 'Название услуги',
  priceTitle: 'Цена в т.ч НДС',
  note: 'Цены и наличие товара уточняйте у менеджеров',
  downloadButton: 'Скачать каталог',
  downloadIcon: 'download.svg',
  watchButton: 'Посмотреть видео',
  watchIcon: 'watch.svg',
}

export const sliderBlock = [
  {
    id: 1,
    title: 'Пресс 100 тонн и ремонт тормозных колодок',
    image: 'sto/slider1.jpg',
  },
  {
    id: 2,
    title: 'Линия диагностического контроля',
    image: 'sto/slider2.jpg',
  },
  {
    id: 3,
    title: 'Основной цех по ремонту техники',
    image: 'sto/slider3.jpg',
  },
  {
    id: 4,
    title: 'Кран балка и подъëмные механизмы',
    image: 'sto/slider4.jpg',
  },
  {
    id: 5,
    title: 'Склад запчастей',
    image: 'sto/slider5.jpg',
  },
]

export const timetableBlock = {
  image: 'sto/sto-timetable.jpg',
  schedules: [
    {
      title: 'График работы СТО',
      timetable: [
        { label: 'ПН-ПТ', value: '09:00 - 18:00' },
        { label: 'Обед', value: '13:00 - 14:00' },
        { label: 'СБ,ВС', value: 'Выходной' },
        { label: 'Тел.', value: '+7 988 314-79-26', note: 'Приёмка сервиса' },
      ],
    },
  ],
}
